import BodyController from './modules/body.controller';
import FormController from './modules/form.controller';
import './helpers/lazy-images';
const angular = window.angular;

const app = angular
  .module(
    'app',
    ['hmTouchEvents'],
    $interpolateProvider => {
      $interpolateProvider.startSymbol('[[');
      $interpolateProvider.endSymbol(']]');
    },
  )
app
  .factory('AppService', $location => {
    return {
      url: $location.protocol() + '://' + $location.host(),
    };
  })
  .filter('isValid', function() {
    return function(obj) {
      var bool = true;

      Object.keys(obj).forEach(function(key) {
        if (!obj[key].isValid) {
          bool = false;
        }
      });

      return bool;
    };
  })
  .controller('BodyController', BodyController)
  .controller('FormController', FormController);
