export default class FormController {
  constructor($http, $scope, $document, $filter, AppService) {
    this.http = $http;
    this.scope = $scope;
    this.document = $document;
    this.filter = $filter;
    this.container = document.querySelector('#contactForm');
    this.appService = AppService;
    this.emailRegex = /^[0-9a-z_.-]+@[0-9a-z.-]+\.[a-z]{2,3}$/i;
    this.phoneRegex = /^[0-9]+$/i;
    this.scope.sentRaport = '';
    this.scope.errorRaport = '';
  }
  initData() {
    this.scope.data = {
      name: '',
      email: '',
      org: '',
      reg: false,
      street: '',
      city: '',
      postcode: '',
      house_flat: ''
    };

    this.scope.valid = {
      name: {
        className: '',
        isValid: false,
      },
      email: {
        className: '',
        isValid: false,
      },
      // org: {
      //   className: '',
      //   isValid: false,
      // },
      // street: {
      //   className: '',
      //   isValid: false,
      // },
      // city: {
      //   className: '',
      //   isValid: false,
      // },
      // postcode: {
      //   className: '',
      //   isValid: false,
      // },
      // house_flat: {
      //   className: '',
      //   isValid: false,
      // },
    };

    this.scope.sendingRaport = '';
  }

  checkName() {
    if (this.scope.data.name.length > 2) {
      this.scope.valid.name.className = '';
      this.scope.valid.name.isValid = true;
    } else {
      this.scope.valid.name.className = 'is-invalid';
      this.scope.valid.name.isValid = false;
    }
  }

  // checkOrg() {
  //   if (this.scope.data.org.length > 1) {
  //     this.scope.valid.org.className = '';
  //     this.scope.valid.org.isValid = true;
  //   } else {
  //     this.scope.valid.org.className = 'is-invalid';
  //     this.scope.valid.org.isValid = false;
  //   }
  // }

  checkClientEmail() {
    if (this.emailRegex.test(this.scope.data.email)) {
      this.scope.valid.email.className = '';
      this.scope.valid.email.isValid = true;
    } else {
      this.scope.valid.email.className = 'is-invalid';
      this.scope.valid.email.isValid = false;
    }
  }

  // checkCity() {
  //   if (this.scope.data.city.length > 2) {
  //     this.scope.valid.city.className = '';
  //     this.scope.valid.city.isValid = true;
  //   } else {
  //     this.scope.valid.city.className = 'is-invalid';
  //     this.scope.valid.city.isValid = false;
  //   }
  // }

  // checkPostcode() {
  //   if (this.scope.data.postcode.length > 2) {
  //     this.scope.valid.postcode.className = '';
  //     this.scope.valid.postcode.isValid = true;
  //   } else {
  //     this.scope.valid.postcode.className = 'is-invalid';
  //     this.scope.valid.postcode.isValid = false;
  //   }
  // }

  // checkStreet() {
  //   if (this.scope.data.street.length > 2) {
  //     this.scope.valid.street.className = '';
  //     this.scope.valid.street.isValid = true;
  //   } else {
  //     this.scope.valid.street.className = 'is-invalid';
  //     this.scope.valid.street.isValid = false;
  //   }
  // }

  // checkHouseFlat() {
  //   if (this.scope.data.house_flat.length > 0) {
  //     this.scope.valid.house_flat.className = '';
  //     this.scope.valid.house_flat.isValid = true;
  //   } else {
  //     this.scope.valid.house_flat.className = 'is-invalid';
  //     this.scope.valid.house_flat.isValid = false;
  //   }
  // }

  submitForm() {
    this.checkName();
    this.checkClientEmail();
    // this.checkOrg();
    // this.checkCity();
    // this.checkPostcode();
    // this.checkStreet();
    // this.checkHouseFlat();
    if (this.filter('isValid')(this.scope.valid) && this.scope.data.reg) {
      this.scope.sendingRaport = 'visible';
      this.http
        .post(this.appService.url + '/api/contact/send', this.scope.data)
        .then(
          res => {
            this.initData();
            this.scope.sentRaport = 'visible';
            this.scope.errorRaport = '';
          },
          err => {
            this.scope.sentRaport = '';
            this.scope.sendingRaport = '';
            this.scope.errorRaport = 'visible';
          },
        );
    }
  }

  closeForm() {
    this.container.classList.remove('visible');
  }
}
