document.addEventListener('DOMContentLoaded', function() {
  const lazyImages = [].slice.call(document.querySelectorAll('img.lazy'));
  const lazyIframes = [].slice.call(document.querySelectorAll('iframe.lazy'));
  const lazyLeads = [].slice.call(document.querySelectorAll('.lazy-lead'));
  const lazyElements = [].slice.call(
    document.querySelectorAll('.lazy-element'),
  );
  const lazyBackgrounds = [].slice.call(
    document.querySelectorAll('.lazy-background'),
  );

  const lazyProgressIcons = [].slice.call(
    document.querySelectorAll('.lazy-progress-icon'),
  );

  if ('IntersectionObserver' in window) {
    let lazyImageObserver = new IntersectionObserver(function(
      entries,
      observer,
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.srcset = lazyImage.dataset.srcset;
          lazyImage.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function(lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });

    let lazyIFrameObserver = new IntersectionObserver(function(
      entries,
      observer,
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          lazyImage.srcset = lazyImage.dataset.srcset;
          lazyImage.classList.remove('lazy');
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyIframes.forEach(function(lazyIf) {
      lazyIFrameObserver.observe(lazyIf);
    });

    let lazyBackgroundObserver = new IntersectionObserver(function(
      entries,
      observer,
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          entry.target.classList.remove('lazy-background');
          lazyBackgroundObserver.unobserve(entry.target);
        }
      });
    });

    lazyBackgrounds.forEach(function(lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });

    let lazyLeadObserver = new IntersectionObserver(function(
      entries,
      observer,
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('start-lead');
          entry.target.classList.add('visible');
          entry.target.classList.remove('lazy-lead');
          lazyLeadObserver.unobserve(entry.target);
        }
      });
    });

    lazyLeads.forEach(function(lazyLead) {
      lazyLeadObserver.observe(lazyLead);
    });

    let lazyElementObserver = new IntersectionObserver(function(
      entries,
      observer,
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('start-element');
          entry.target.classList.remove('lazy-element');
          lazyElementObserver.unobserve(entry.target);
        }
      });
    });

    lazyElements.forEach(function(lazyElement) {
      lazyElementObserver.observe(lazyElement);
    });

    let lazyProgressIconsObserver = new IntersectionObserver(function(
      entries,
      observer,
    ) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          entry.target.classList.add('start-progress-icon');
          entry.target.classList.remove('lazy-progress-icon');
          setTimeout(() => {
            entry.target.classList.remove('start-progress-icon');
          }, 1200);
          lazyProgressIconsObserver.unobserve(entry.target);
        }
      });
    });

    lazyProgressIcons.forEach(function(lazyPrI) {
      lazyProgressIconsObserver.observe(lazyPrI);
    });
  } else {
    const imgs = document.querySelectorAll('img.lazy');
    Object.keys(imgs).map(k => {
      let image = k.getAttribute('data-src');
      imgs[k].src = image;
      imgs[k].classList.remove('lazy');
    });
    const iframes = document.querySelectorAll('iframe.lazy');
    Object.keys(iframes).map(k => {
      let iframeUrl = k.getAttribute('data-src');
      iframes[k].src = iframeUrl;
      iframes[k].classList.remove('lazy');
    });
    const bgs = document.querySelectorAll('.lazy-background');
    Object.keys(bgs).map(k => {
      bgs[k].classList.remove('lazy-background');
      bgs[k].classList.add('visible');
    });
    const bgsl = document.querySelectorAll('.lazy-lead');
    Object.keys(bgsl).map(k => {
      bgsl[k].classList.add('start-element');
      bgsl[k].classList.remove('lazy-lead');
    });
    const bgse = document.querySelectorAll('.lazy-element');
    Object.keys(bgse).map(k => {
      bgse[k].classList.add('start-lead');
      bgse[k].classList.add('visible');
      bgse[k].classList.remove('lazy-element');
    });
    const pri = document.querySelectorAll('.lazy-progress-icon');
    Object.keys(pri).map(k => {
      pri[k].classList.add('start-progress-icon');
      pri[k].classList.remove('lazy-progress-icon');
    });
  }
});
